import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import ChartOfAccountSplitsView from
  '@src/components/accounts_payable/invoice_queue/action_icons/chart_of_account_view/chart_of_account_splits_view';
import SplitCategoryForm from '@src/components/common_v2/category_splits_modal/category_splits_form';
import {
  categorySplitsValidation,
  ICategorySplit,
  ICategorySplitsData,
} from '@src/components/common_v2/category_splits_modal/schema';
import Form from '@src/components/ui_v2/form';
import {
  DOCUMENT_WIDTH_PERCENTAGE_HALF,
  MIN_DOCUMENT_WIDTH_PERCENTAGE,
} from '@src/components/ui_v2/layout/document_view/constant';
import { useDocumentViewWidth } from '@src/components/ui_v2/layout/document_view/hooks';

import { getFormFieldLabel, isFieldRequired } from '../utils';

import styles from './styles.module.scss';

interface ICategoryFieldProps {
  document: IDocument;
  serviceDocument: IAccountsPayableServiceDocument;
}

const CategoryField = ({ document, serviceDocument }: ICategoryFieldProps) => {
  const { documentWidthPercentage, setDocumentWidthPercentage } = useDocumentViewWidth();
  const isCoaSplitsView = (100 - documentWidthPercentage) < DOCUMENT_WIDTH_PERCENTAGE_HALF;

  const form = useForm<ICategorySplitsData>({
    reValidateMode: 'onChange',
    resolver:       yupResolver(categorySplitsValidation),
  });
  const { reset } = form;

  const handleEdit = useCallback(() => {
    setDocumentWidthPercentage(MIN_DOCUMENT_WIDTH_PERCENTAGE);
  }, [setDocumentWidthPercentage]);

  useEffect(() => {
    // set extracted line items to form
    if (serviceDocument.serviceDocumentLineItems?.length) {
      const splits: ICategorySplit[] = serviceDocument.serviceDocumentLineItems.map((lineItem) => {
        return {
          amount:              lineItem.amount,
          memo:                lineItem.description,
          accountingClassId:   lineItem.predictedAccountingClassId,
          accountingClassName: lineItem.predictedAccountingClassName,
          chartOfAccountId:    lineItem.predictedChartOfAccountId,
          chartOfAccountName:  lineItem.predictedChartOfAccountName,
          businessId:          document.businesses[0].id,
          businessName:        document.businesses[0].name,
        };
      });
      reset({
        adjustmentAmount: serviceDocument.invoiceAmount,
        splits,
      });
    }
  }, [document, serviceDocument, reset]);

  return (
    isCoaSplitsView ? (
      <div className={ styles['document-fields'] }>
        <Form.StaticField
          label={
            getFormFieldLabel('Category', isFieldRequired(window.configData.account_payable_invoice_coa_field_id))
          }
        >
          <ChartOfAccountSplitsView
            shortSelectText
            accountsPayableServiceDocument={ serviceDocument }
            onEdit={ handleEdit }
          />
        </Form.StaticField>
      </div>
    ) : (
      <div className={ styles['splits-form-container'] }>
        <p className={ styles['splits-form-title'] }>Categorization</p>
        <SplitCategoryForm
          business={ document.businesses[0] }
          form={ form }
        />
      </div>
    )
  );
};

export default React.memo(CategoryField);
