/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

import { omit } from 'lodash';
import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetTaskServiceDocuments } from '@src/hooks/queries/task_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetTaskServiceDocumentsResponse } from '@src/requests/task_service_documents';
import { TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { ITaskServiceDocument, ITaskServiceDocumentFilterQuery,
  TTasksDocumentsSortColumn } from '@src/types/task_service_documents';

import { useFilterData } from '@src/components/ui_v2/filter';

import { StatusKey } from './types';

interface IUseTaskCollectionParams {
  statuses: StatusKey[];
  tab: keyof typeof window.Docyt.Common.Constants.TASKS;
}

export interface ITasksCollection {
  query: UseInfiniteQueryResult<IGetTaskServiceDocumentsResponse, Error>,
  records: ITaskServiceDocument[],
  section: TSection,
  sorting: ISorting<TTasksDocumentsSortColumn>,
}

const tasksDefaultSorting: ISortingParams<TTasksDocumentsSortColumn> = {
  orderColumn:    'taskId',
  orderDirection: 'desc',
};

const getTasksParamsFromFilter = (filterData: ITaskServiceDocumentFilterQuery) => {
  const filterParams = omit(filterData || {});
  const statuses = filterParams?.statuses;
  const businessIds = filterParams?.selectedBusinessIds;
  const userIds = filterParams?.selectedUserIds;
  const excludedBusinessIds = filterParams?.excludedBusinessIds;
  const excludedUserIds = filterParams?.excludedUserIds;
  if (statuses) {
    if (typeof statuses === 'object') {
      filterParams.statuses = Object.values(statuses) as StatusKey[];
    } else if (typeof statuses === 'string') {
      filterParams.statuses = [statuses];
    }
  }
  if (businessIds && typeof businessIds === 'object') {
    filterParams.selectedBusinessIds = Object.values(businessIds);
  }
  if (userIds && typeof userIds === 'object') {
    filterParams.selectedUserIds = Object.values(userIds);
  }
  if (excludedBusinessIds && typeof excludedBusinessIds === 'object') {
    filterParams.excludedBusinessIds = Object.values(excludedBusinessIds);
  }
  if (excludedUserIds && typeof excludedUserIds === 'object') {
    filterParams.excludedUserIds = Object.values(excludedUserIds);
  }
  if (statuses && typeof statuses === 'string') {
    filterParams.statuses = [statuses];
  }
  return {
    filter: filterParams,
  };
};

const useTaskServiceCollection = ({
  statuses,
  tab,
}: IUseTaskCollectionParams): ITasksCollection => {
  const defaultFilter = useMemo(() => {
    return { statuses };
  }, [tab, statuses]);
  const section = useMemo(() => {
    return {
      tab,
      section: tab,
    };
  }, [tab]);

  const filterData = useFilterData(section, defaultFilter);
  const filterParams = useMemo(() => {
    const filterObj = getTasksParamsFromFilter(filterData as ITaskServiceDocumentFilterQuery);
    if (!(filterObj?.filter?.statuses)) {
      filterObj.filter.statuses = statuses;
    }
    return filterObj;
  }, [filterData]);

  const sorting = useSorting<TTasksDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: tasksDefaultSorting,
  });
  const query = useGetTaskServiceDocuments({
    page: 1,
    ps:   20,
    ...sorting.data,
    ...filterParams,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  IUseTaskCollectionParams,
  useTaskServiceCollection,
};
