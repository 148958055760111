import React, { useCallback, useEffect, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetSystemCategories } from '@src/hooks/queries/transaction_service_document';
import { useUpdateTransactionServiceDocumentDoNotLearn } from '@src/hooks/queries/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { useClearFilter } from '@src/components/ui_v2/filter/hooks';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import { useTSDCollection } from './hooks';
import List from './list';
import Sidebar from './sidebar';

import styles from './styles.module.scss';

const AllTransactions = ({ title, type }: { title?: string, type?: string }) => {
  const business = useBusinessContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [rowData, setRowData] = useState<ITransactionServiceDocument | null>(null);

  const tsdCollection = useTSDCollection({ businessId: business.id, excluded: false, type });

  const totalTransactions = tsdCollection.query.data?.pages[0].meta.totalCount;
  const totalAmount = tsdCollection.query.data?.pages[0].meta.totalAmount || 0;
  const formattedTotalAmount = totalAmount > 0 ? `$${Math.abs(totalAmount)}` : `-$${Math.abs(totalAmount)}`;

  const updateTransactionServiceDocumentDoNotLearn = useUpdateTransactionServiceDocumentDoNotLearn();
  const { mutateAsync: updateTransactionServiceDocumentDoNotLearnMutate } = updateTransactionServiceDocumentDoNotLearn;
  const systemCategoriesQuery = useGetSystemCategories(business.id);

  const updateRowData = useCallback((currentRowData: ITransactionServiceDocument | null) => {
    if (currentRowData) {
      const pages = tsdCollection.query.data?.pages || [];
      const updatedData = pages.flatMap((page) => page.collection).find(
        (item: ITransactionServiceDocument) => item.id === currentRowData.id,
      );

      setRowData(updatedData || currentRowData);
    }
  }, [tsdCollection.query.data]);

  const handleRowClick = async (data: ITransactionServiceDocument) => {
    updateRowData(data);
    setIsSidebarOpen(true);
  };

  const handleRefreshData = async () => {
    await tsdCollection.query.refetch();
  };

  useEffect(() => {
    const handleAddDocument = async (document: ITransactionServiceDocument) => {
      // @ts-ignore
      const businessModel = new window.Docyt.Entities.Business(business);
      // @ts-ignore
      const rcService = new window.Docyt.Entities.ReconciliationCenter();

      try {
        const [rcData] = await Promise.all([
          rcService.fetch({
            url: `/api/v1/reconciliation_centers/by_business_id?business_id=${business.id}`,
          }),
        ]);

        rcService.set('reconciliation_center', rcData.reconciliation_center);

        rcService.get = (key: string) => rcService.attributes[key];

        // @ts-ignore
        const addDocumentMixin = window.Docyt.Common.Mixins.AddDocument;
        // @ts-ignore
        addDocumentMixin.onAddDocument(rcService, businessModel, document, true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in handleAddDocument:', error);
      }

      // @ts-ignore
      window.Docyt.vent.once('add:related:document:done', handleRefreshData);
    };

    const handleNoDocumentNeeded = (document: ITransactionServiceDocument) => {
      const data = {
        id:          document.id,
        document_id: document.documentId,
      };

      const model = {
        ...data,
        get(key: string) { return data[key as keyof typeof data]; },
        set(key: string, value: any) { data[key as keyof typeof data] = value; },
        toJSON() { return data; },
        trigger() {},
      };

      // @ts-ignore
      // eslint-disable-next-line max-len
      const modalView = new window.Docyt.AdvisorHomeApp.Businesses.Show.Services.Show.ReconciliationCenter.AddDocumentNoteModal({
        model,
      });

      modalView.on('document:note:added', async () => {
        try {
          await updateTransactionServiceDocumentDoNotLearnMutate({
            id:                 document.id,
            noDocumentRequired: true,
          });

          // @ts-ignore
          window.Docyt.vent.trigger('hide:spinner');
          // @ts-ignore
          window.Docyt.vent.trigger('transaction:match:list:added');
          // @ts-ignore
          window.Docyt.vent.trigger('transaction:document:note:added');

          handleRefreshData();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error in handleNoDocumentNeeded:', error);
        }
      });

      // @ts-ignore
      window.Docyt.secondModalRegion.show(modalView);
    };

    // @ts-ignore
    window.Docyt.vent.on('related:document:add', handleAddDocument);
    // @ts-ignore
    window.Docyt.vent.on('transaction:document:note:add', handleNoDocumentNeeded);

    return () => {
      // @ts-ignore
      window.Docyt.vent.off('related:document:add', handleAddDocument);
      // @ts-ignore
      window.Docyt.vent.off('add:related:document:done', handleRefreshData);
      // @ts-ignore
      window.Docyt.vent.off('transaction:document:note:add', handleNoDocumentNeeded);
    };
  }, [business, handleRefreshData]);

  useEffect(() => {
    updateRowData(rowData);
  }, [tsdCollection.query.data, rowData, updateRowData]);

  return (
    <div className={ styles.container }>
      <DetailsRegion>
        <DetailsRegion.Header title={ title || 'All Transactions' } />
        <div className={ styles['tables-container'] }>
          <List
            collection={ tsdCollection }
            payrollBCOAId={ systemCategoriesQuery.query.data?.payrollBusinessChartOfAccount?.chartOfAccountId || null }
            systemCategories={ systemCategoriesQuery.query.data || null }
            type={ type }
            onRowClick={ handleRowClick }
          />
        </div>
        <div className={ styles['total-transactions'] }>
          Total Transactions:
          <span className={ styles['total-transactions-value'] }>{ totalTransactions }</span>
          <span>｜</span>
          Total Amount:
          <span className={ styles['total-transactions-value'] }>{ formattedTotalAmount }</span>
        </div>
      </DetailsRegion>
      { isSidebarOpen && rowData && (
        <Sidebar
          key={ rowData?.id }
          businessId={ business.id }
          isLoading={ false }
          reloadSystemCategories={ systemCategoriesQuery.query.refetch }
          rowData={ rowData }
          setRowData={ setRowData }
          systemCategories={ systemCategoriesQuery.query.data || null }
          onClose={ () => setIsSidebarOpen(false) }
          onRefreshData={ handleRefreshData }
        />
      )}
    </div>
  );
};

export default React.memo(AllTransactions);
