import React from 'react';

import { IAddress } from '@src/types/address';

import Badge from '@src/components/ui_v2/badge';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';

import styles from './styles.module.scss';

interface IPaymentAddressFieldProps {
  address?: IAddress;
}

const PaymentAddressField = ({ address }: IPaymentAddressFieldProps) => {
  return (
    <Form.StaticField label="Payment Address">
      <div>
        {address
          ? (
            <div className={ styles['payment-address-field-content'] }>
              <div>{ address.name }</div>
              <div>{ address.company }</div>
              <div>{ address.addressLine1 }</div>
              <div>{ address.addressLine2 }</div>
              <div>
                { address.addressCity }
                {' '}
                { address.addressState }
                {' '}
                { address.addressZip }
              </div>
              <div>{ address.addressCountry }</div>
              { address.isAuthorized && (
                <div className={ styles['payment-address-field-badge'] }>
                  <Badge label="Authorised" variant="success" />
                </div>
              )}
            </div>
          )
          : null}
        <div>
          <Button variant="link">Edit</Button>
        </div>
      </div>
    </Form.StaticField>
  );
};

export default React.memo(PaymentAddressField);
