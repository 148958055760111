import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';

const PeriodFields = () => {
  return (
    <Form.StaticField label="Period">
      <Button variant="link">
        Multiple Months
      </Button>
    </Form.StaticField>
  );
};

export default React.memo(PeriodFields);
