import { useMemo } from 'react';

import { flatten, omit } from 'lodash';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetTaskNotification, useGetTaskNotificationCount } from '@src/hooks/queries/task_notification';
import { IGetTaskNotificationCountResponse } from '@src/requests/task_notification';
import { ITask } from '@src/types/task_notification';

import { availableStatuses } from '@src/components/task_management/components/helper/constants';

interface ITaskNotificationCollection {
  query: UseInfiniteQueryResult<IGetTaskNotificationCountResponse, Error>;
  records: ITask[];
}

const useGetTaskNotificationData = () => {
  const query = useGetTaskNotification({
    page: 1,
    ps:   10,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const totalCount = query?.data?.pages[0].meta.totalCount;

  return {
    query,
    records,
    totalCount,
  };
};

const useGetTaskNotificationTotalCount = () => {
  const getTasksCountParamsFromFilter = (filterData: any) => {
    const filterParams = omit(filterData || {});
    return {
      filter: filterParams,
    };
  };

  const filterParams = useMemo(() => {
    return getTasksCountParamsFromFilter({ statuses: [availableStatuses.OPEN] });
  }, []);

  const query = useGetTaskNotificationCount({
    page: 1,
    ps:   10,
    ...filterParams,
  });

  const totalCount = query?.data?.pages[0].meta.totalCount;

  return {
    totalCount,
    query,
  };
};

export {
  ITaskNotificationCollection,
  useGetTaskNotificationData,
  useGetTaskNotificationTotalCount,
};
