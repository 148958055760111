import { useQuery, useMutation } from 'react-query';

import {
  IGetDocumentFieldsParams,
  IGetDocumentFieldsResponse,
  getDocumentFields,
  ISaveDocumentFieldValueParams,
  ISaveDocumentFieldValueResponse,
  saveDocumentFieldValue,
  updateVendor,
  IUpdateVendorParams,
  IUpdateVendorResponse,
} from '@src/requests/document_field';

const DOCUMENT_FIELDS_QUERY = 'document_fields';

const useGetDocumentFields = (params: IGetDocumentFieldsParams) => {
  return useQuery<IGetDocumentFieldsResponse, Error>(
    [DOCUMENT_FIELDS_QUERY, params],
    () => getDocumentFields(params),
  );
};

const useSaveDocumentFieldValue = () => {
  return useMutation<ISaveDocumentFieldValueResponse, Error, ISaveDocumentFieldValueParams>(
    saveDocumentFieldValue,
  );
};

const useUpdateVendor = () => {
  return useMutation<IUpdateVendorResponse, Error, IUpdateVendorParams>(
    updateVendor,
  );
};

export {
  useGetDocumentFields,
  useSaveDocumentFieldValue,
  useUpdateVendor,
};
